.landing-page {
    width: 100%;
    height: 100%;
    background-color: #0F0F0F;
}

.landing-header {
    position: fixed;
    top: 50px;
    width: 100%;
    left: 0;
    z-index: 10000;
    padding: 0 90px;
}

.navigation {
    height: 0;
    overflow: hidden;
    transition: 0.5s ease;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

.navigation button {
    display: flex;
    background-color: transparent;
    border: none;
    color: white;
    font-size: 20px;
    font-family: 'Inter';
    padding-left: 20px;
}

.navigation a {
    color: white;
    font-size: 20px;
    font-family: 'Inter';
    padding-left: 20px;
}
  
  .navigation.show {
    height: 200px; 
    padding-top: 10px;
  }
  
  .navigation.hide {
    height: 0;
    padding-top: 0;
  }

.landing-header-right {
    display: flex;
    gap: 20px;
    align-items: center;
}

.lang {
    color: white;
    font-size: 14px;
    font-family: 'Inter';
    border-radius: 16px;
    border: 1px solid white;
    padding: 2px 7px;
    cursor: pointer;
}

.landing-header-logo {
    color: white;
    font-size: 20px;
    font-family: 'Forum';
}

.landing-header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.landing-header-full {
    background: rgba(255, 255, 255, 0.01);
    backdrop-filter: blur(40px);
    padding: 7px 30px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    border-radius: 24px;
}

.hiro {
    height: 100vh;
}

.hiro-bg {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: relative;
}

.hiro-column {
    display: flex;
    flex-direction: column;
    gap: 30px;
    position: absolute;
    z-index: 10;
    bottom: 100px;
    left: 90px;
}

.hiro-column-title {
    font-family: 'Inter';
    font-size: 72px;
    color: white;
    width: 60%;
    line-height: 1;
}

.hiro-column-text {
    font-family: 'Inter';
    font-size: 18px;
    color: white;
    width: 50%;
    margin-bottom: 20px;
}

.landing-button {
    background-color: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 24px;
    font-family: 'Inter';
    font-size: 20px;
    padding: 7px 40px;
    cursor: pointer;
    text-decoration: none;
}

.landing-button-ads {
    background-color: white;
    color: #7B2C2C;
    border: none;
    border-radius: 24px;
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 700;
    padding: 7px 40px;
    cursor: pointer;
    text-decoration: none;
}

.two {
    padding: 100px 90px;
    background-color: #0F0F0F;
}

.two-title {
    font-family: 'Inter';
    font-size: 72px;
    color: white;
    width: 60%;
    line-height: 1;
    margin-bottom: 60px;
}

.two-text {
    font-family: 'Inter';
    font-size: 18px;
    color: white;
    width: 30%;
    margin-bottom: 100px;
}

.landing-label {
    font-family: 'Inter';
    font-size: 14px;
    padding: 5px 20px;
    border-radius: 24px;
    color: #FF8A8A;
    border: 1px solid #FF8A8A;
    display: inline-flex;
    margin-bottom: 40px;
}

.books {
    display: flex;
    gap: 20px;
    overflow-x: auto;
}

/* .book {
    animation: scroll 5s linear infinite;
} */

.book {
    height: 400px;
    object-fit: cover;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

.books::-webkit-scrollbar {
    display: none;
}

.three {
    padding: 50px 90px;
    background-color: #0F0F0F;
}

.three-text {
    font-family: 'Inter';
    font-size: 18px;
    color: white;
    width: 40%;
    margin-bottom: 50px;
}

.receiver-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
    margin-bottom: 120px;
}

.receiver-card {
    background-color: #191919;
    padding: 16px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    color: white;
    font-size: 14px;
    font-family: 'Inter';
}

.survey {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.survey-title {
    font-family: 'Inter';
    font-size: 36px;
    color: white;
    width: 70%;
    line-height: 1.2;
}

.process {
    padding: 50px 90px;
    background-color: #0F0F0F;
}

.process-1 {
    display: flex;
    justify-content: space-between;
}

.process-text {
    font-family: 'Inter';
    font-size: 14px;
    color: #727272;
}

.process-2 {
    display: flex;
    width: 100%;
    margin-bottom: 100px;
}

.process-step {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.process-label {
    color: #727272;
    font-family: 'Inter';
    font-size: 18px;
    letter-spacing: 5px;
}


.process-step-text {
    font-family: 'Inter';
    font-size: 18px;
    color: white;
    width: 70%;
}

.process-img {
    width: 50%;
    border-radius: 12px;
}

.mockups {
    width: 50%;
        overflow-x: auto;
    display: flex;
    gap: 10px;
}

.mockup {
    height: 400px;
    object-fit: cover;
}

.mockups::-webkit-scrollbar {
    display: none;
}

.results {
    padding: 50px 90px;
    background-color: #0F0F0F;
}

.results-title {
    font-family: 'Inter';
    font-size: 36px;
    color: white;
    width: 60%;
    line-height: 1.2;
    margin-bottom: 50px;
}

.results-grid {
    display: flex;
    overflow-x: auto;
    gap: 20px;
    margin-bottom: 120px;
}

.results-grid::-webkit-scrollbar {
    display: none;
}

.results-img {
    border-radius: 12px;
    width: 250px;
}

.review-img {
    border-radius: 12px;
    max-width: 300px;
}

.reviews-title {
    font-family: 'Inter';
    font-size: 36px;
    color: white;
    width: 60%;
    line-height: 1.2;
    margin-bottom: 30px;
}

.reviews-row {
    display: flex;
    gap: 16px;
    overflow-x: auto;
    margin-bottom: 70px;
}

.reviews-row::-webkit-scrollbar {
    display: none;
}

.price {
    padding: 50px 90px;
    background-color: #0F0F0F;
}

.price-text {
    font-family: 'Inter';
    font-size: 18px;
    color: white;
    width: 30%;
    margin-bottom: 30px;
}

.end {
    padding: 100px 90px;
    background-color: #1C1C1C;
}

.end-title1 {
    font-family: 'Inter';
    font-size: 72px;
    color: white;
    width: 70%;
    line-height: 1.2;
    margin-bottom: 30px;
}

.end-title2 {
    font-family: 'Inter';
    font-size: 36px;
    color: white;
    width: 70%;
    line-height: 1.2;
    margin-bottom: 60px;
}

@media (max-width: 700px) {
    .landing-page {
        width: 100%;
        height: 100%;
        background-color: #0F0F0F;
        position: absolute;
        top: 0;
        left: 0;
    }

    .landing-header {
        position: fixed;
        top: 50px;
        width: unset;
        left: 0;
        z-index: 10000;
        padding: 0 10px;
        width: 100%;
    }

    .landing-header-full {
        background: rgba(255, 255, 255, 0.01);
        backdrop-filter: blur(40px);
        padding: 10px 16px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        border-radius: 24px;
    }

    .hiro-column {
        display: flex;
        flex-direction: column;
        gap: 20px;
        position: absolute; 
        z-index: 10;
        bottom: 20px;
        left: 20px;
    }

    .hiro {
        max-height: 100vh;
    }
    
    .hiro-bg {
        max-height: 100vh;
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: relative;
    }

    .hiro-column-text {
        font-family: 'Inter';
        font-size: 18px;
        color: white;
        width: 90%;
        margin-bottom: 20px;
    }

    .two, .three, .end, .process, .results, .price {
        padding-left: 20px;
        padding-right: 20px;
    }

    .results, .price {
        padding-top: 0px;
    }

    .results-grid {
        display: flex;
        overflow-x: auto;
        gap: 5px;
        margin-bottom: 120px;
    }

    .receiver-grid {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 10px;
        overflow-x: auto;
        margin-bottom: 120px;
    }

    .receiver-card {
        background-color: #191919;
        padding: 16px 20px;
        display: flex;
        justify-content: unset;
        align-items: center;
        border-radius: 12px;
        color: white;
        font-size: 14px;
        font-family: 'Inter';
        gap: 100px;
    }

    .receiver-grid::-webkit-scrollbar {
        display: none;
    }

    .results-grid::-webkit-scrollbar {
        display: none;
    }

    .two-title {
        font-family: 'Inter';
        font-size: 72px;
        color: white;
        width: 100%;
        line-height: 1;
        margin-bottom: 60px;
    }
    
    .two-text {
        font-family: 'Inter';
        font-size: 18px;
        color: white;
        width: 100%;
        margin-bottom: 80px;
    }

    .books {
        display: flex;
        gap: 10px;
        overflow-x: auto;
    }

    .book {
        height: 400px;
        object-fit: cover;
    }
    
    .books::-webkit-scrollbar {
        display: none;
    }

    .three-text {
        font-family: 'Inter';
        font-size: 18px;
        color: white;
        width: 100%;
        margin-bottom: 50px;
    }

    .survey {
        display: flex;
        flex-direction: column;
        align-items: unset;
        justify-content: unset;
        gap: 30px;
    }
    
    .survey-title {
        font-family: 'Inter';
        font-size: 36px;
        color: white;
        width: 100%;
        line-height: 1.2;
    }

    .survey-icon {
        width: 100px;
        object-fit: cover;
        order: 1;
    }

    .process-2 {
        flex-direction: column;
        margin-bottom: 80px;
    }
    
    .process-step {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .process-step-text {
        font-family: 'Inter';
        font-size: 18px;
        color: white;
        width: 100%;
        margin-bottom: 30px;
    }
    
    .process-img {
        width: 100%;
        border-radius: 12px;
    }

    .mockups {
        width: 100%;
    }

    .results-title {
        width: 100%;
    }

    .reviews-title {
        font-family: 'Inter';
        font-size: 32px;
        color: white;
        width: 100%;
        line-height: 1.2;
        margin-bottom: 30px;
    }

    .price-text {
        font-family: 'Inter';
        font-size: 18px;
        color: white;
        width: 100%;
        margin-bottom: 30px;
    }
    
    .end-title2 {
        font-family: 'Inter';
        font-size: 36px;
        color: white;
        width: 100%;
        line-height: 1.2;
        margin-bottom: 60px;
    }
}