.policy-container {
    padding: 60px 450px;
    display: flex;
    flex-direction: column;
    background-color: #3f1616;
    color: white;
    position: absolute;
    top: 0;
    left: 0;
}

.policy-title {
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 600;
    margin-top: 100px;
    margin-bottom: 60px;
}

.policy-term {
    margin-bottom: 30px;
    gap: 5px;
    display: flex;
    flex-direction: column;
}

.policy-term-title {
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
}

.policy-term-text {
    font-family: 'Inter';
    font-size: 16px;
}

@media (max-width: 900px) {
    .policy-container {
        padding: 60px 20px;
    }
}