.cover-page {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
    padding-left: 60px;
    padding-right: 60px;
  }

  .cover-info {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-self: center;
  }
  
  .cover-preview {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 40%;
    align-self: center;
  }

  .cover-preview-mobile {
    display: none;
  }

  .cover {
    height: 100%;
    width: 100%;
    position: relative;
  }
  
  .cover-itself {
    max-height: 100%;
    max-width: 100%;
  }
  
  .cover-contains-coverOption1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 26%;
    padding-right: 26%;
    padding-top: 27.3%;
    padding-bottom: 24.1%;
    z-index: 10;
    color: black;
    justify-content: space-between;
    opacity: 0.8;
  }

  .book-title-coverOption1 {
    align-self: center;
    font-size: 52px;
    line-height: 0.9;
    text-align: center;
    font-family: 'Leotaro';
    color: white;
  }

  .book-names-coverOption1 {
    align-self: center;
    display: flex;
    flex-direction: column;
    font-family: 'TT Drugs';
    color: white;
    font-size: 14px;
    text-align: center;
  }

  .cover-contains-coverOption2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 26%;
    padding-right: 26%;
    padding-top: 61.3%;
    padding-bottom: 58.1%;
    z-index: 10;
    color: black;
    justify-content: space-between;
    opacity: 0.8;
  }

  .book-title-coverOption2 {
    align-self: center;
    font-size: 36px;
    line-height: 0.9;
    text-align: center;
    font-family: 'Leotaro';
  }

  .book-names-coverOption2 {
    align-self: center;
    display: flex;
    flex-direction: column;
    font-family: 'TT Drugs';
    font-size: 14px;
    text-align: center;
  }

  .author-name-coverOption2 {
    font-size: 18px;
  }

  .cover-contains-coverOption3 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 26%;
    padding-right: 26%;
    padding-top: 27.3%;
    padding-bottom: 24.1%;
    z-index: 10;
    color: black;
    justify-content: space-between;
    opacity: 0.8;
  }

  .book-title-coverOption3 {
    align-self: center;
    font-size: 52px;
    line-height: 0.9;
    text-align: center;
    font-family: 'Leotaro';
    color: white;
  }

  .book-names-coverOption3 {
    align-self: center;
    display: flex;
    flex-direction: column;
    font-family: 'TT Drugs ';
    color: white;
    font-size: 14px;
    text-align: center;
  }

  .author-name-coverOption3 {
    font-size: 18px;
  }
  
    .cover-contains-coverOption4 {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding-left: 26%;
      padding-right: 26%;
      padding-top: 61.3%;
      padding-bottom: 55.1%;
      z-index: 10;
      color: black;
      justify-content: space-between;
      opacity: 0.8;
    }
  
  .book-title-coverOption4 {
    align-self: center;
    font-size: 24px;
    text-align: center;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }

  .book-names-coverOption4 {
    align-self: center;
    display: flex;
    flex-direction: column;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    text-align: center;
    }

  .input-title {
    font-size: 16px;
    font-family: 'Noto Sans';
    color: black;
    margin-bottom: 10px;
  }

  .cover-inputs {
    /* margin-top: 100px; */
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 100%;
    padding-right: 50px;
  }
  
  .cover-inputs input {
    background-color: transparent;
    border-radius: 6px;
    height: 80px;
    width: 100%;
    font-size: 16px;
    padding-left: 20px;
  }

  .cover-inputs input:not(:disabled) {
    background-color: white;
    border: 1px solid #D9D9D9;
}

.cover-inputs input:disabled {
    background-color: #DDDDDD;
}

  .cover-inputs input:focus {
    outline: 0px;
  }

  .cover-inputs-select {
    background-color: transparent;
    border-radius: 6px;
    height: 80px;
    width: 100%;
    font-size: 16px;
    padding-left: 20px;
  }

  .cover-inputs-select:not(:disabled) {
    background-color: white;
    border: 1px solid #D9D9D9;
}

.cover-inputs-select:disabled {
    background-color: #DDDDDD;
}

  .cover-inputs-select:focus {
    outline: 0px;
  }

  .input-save-button button {
    padding: 10px 40px;
    border: 0px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Noto Sans';
    margin-top: 50px;
    background-color: #363E50;
    color: white;
  }

  
  .color-selector {
    display: flex;
    gap: 10px;
    margin-top: 50px;
  }
  
  .color-selector button {
    width: 40px;
    height: 40px;
    border-radius: 6px;
    border: 1px solid #272F40;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .color-selector button:hover {
    transform: scale(1.1);
  }
  
  .nopartner label {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
  }

  .nopartner label input {
    width: 20px;
    height: 20px;
  }
  

  @media (max-width: 700px) {
    .cover-page {
      flex-direction: column;
      gap: 50px;
      padding: 0;
      margin-top: 70px;
    }

    .cover-menu {
        gap: 20px;
        padding-right: unset;
    }

    .cover-info {
        width: 100%;
        margin-bottom: 100px;
    }

    .cover-preview-mobile {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        width: 100%;
        margin-top: 20px;
      }

    .cover-preview {
        display: none;
    }

    .cover-inputs {
        padding-right: unset;
        gap: 20px;
        margin-top: 20px;
    }

    .cover-inputs input {
        height: 60px;
        border-radius: 8px;
    }


  .cover-inputs-select {
      height: 60px;
      border-radius: 8px;
  }

    .color-selector {
        margin-top: 20px;
    }

    .input-save-button button {
        margin-top: 20px;
    }

    .book-title-coverOption1 {
      font-size: 36px;
    }

    .book-names-coverOption1 {
      font-size: 10px;
    }

    .book-title-coverOption2 {
      font-size: 24px;
    }

    .book-names-coverOption2 {
      font-size: 10px;
    }

    .author-name-coverOption2 {
      font-size: 14px;
    }

    .book-title-coverOption3 {
      font-size: 36px;
    }

    .book-names-coverOption3 {
      font-size: 10px;
    }

    .author-name-coverOption3 {
      font-size: 14px;
    }

    .book-title-coverOption4 {
      font-size: 20px;
    }

    .book-names-coverOption4 {
      font-size: 10px;
    }
  }

  @media (min-width: 1300px) {
    .cover-itself {
      max-height: 100%;
      max-width: 100%;
      min-width: 534px;
    }
  }
  