.onhold {
    font-family: 'Inter';
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
    justify-content: center;
}

.onhold-img {
    width: 100px;
    object-fit: cover;
    margin-bottom: 30px;
}

.onhold-title {
    font-size: 20px;
    text-align: center;
    margin-bottom: 30px;
    font-weight: 600;
    color: rgb(52, 96, 201)
}

.onhold-text {
    font-size: 14px;
    text-align: center;
    margin-bottom: 50px;
    width: 40%;
    font-weight: 300;
    color: rgb(24, 38, 71)
}

.onhold-bold {
    font-size: 14px;
    text-align: center;
    margin-bottom: 20px;
    width: 40%;
    font-weight: 600;
    color: rgb(41, 75, 155)
}

.onhold-button {
    color: white;
    background: rgb(21, 47, 107);
    border-radius: 6px;
    border: none;
    font-size: 14px;
    padding: 10px 16px;
    cursor: pointer;
}

@media (max-width: 900px) {
    .onhold-text {
        font-size: 14px;
        text-align: center;
        margin-bottom: 50px;
        width: 100%;
        font-weight: 300;
    }

    .onhold-bold {
        width: 100%;
    }
}