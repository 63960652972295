.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  width: 17%;
  overflow-y: auto;
  background: linear-gradient(360deg, rgba(18,22,31,1) 0%, rgba(39,46,61,1) 100%);
  /* padding: 20px; */
  height: 100vh;
  z-index: 100000;
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.page-numbers {
  margin-top: 10px;
  color: white;
  font-size: 12px;
  /* font-weight: 700; */
  font-family: "Noto Sans";
}

.deadline {
  margin-top: 10px;
  color: rgb(224, 237, 255);
  font-size: 11px;
  font-family: "Noto Sans";
}

.forms {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.forms-info-container {
  display: flex;
  flex-direction: column;
  width: 17%;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  padding: 30px 20px 20px 20px;
  gap: 5px;
  /* background-color: black; */
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(40px);
}

.questions-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-top: 200px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 200px;
}

.photo-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-top: 200px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 150px;
}

.photo-list-one {
  text-align: left;
  width: 100%;
  background-color: #4F545B;
  border: none;
  border-radius: 6px;
  padding: 16px;
  cursor: pointer;
  color: white;
  margin-bottom: 10px;
  font-family: "Noto Sans";
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.photo-list-one-test {
  text-align: center;
  border: none;
  border-radius: 6px;
  padding: 16px;
  cursor: pointer;
  color: white;
  margin-bottom: 10px;
  font-family: "Noto Sans";
  font-size: 12px;
  padding-top: 150px;
  padding-left: 20px;
  padding-right: 20px;
}

.questions-list li button {
  text-align: left;
  width: 100%;
  background: none;
  border: none;
  padding: 10px;
  cursor: pointer;
  color: white;
  margin-bottom: 10px;
  font-family: "Noto Sans";
  font-size: 12px;
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sidebar-bottom-fixed {
  display: flex;
  flex-direction: column;
  width: 17%;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 20px;
  gap: 5px;
  /* background-color: black; */
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(40px);
  border-top: 1px solid #58626E
}

.sidebar-bottom-fixed-cover {
  width: 100%;
  border: 0px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 12px;
  font-family: "Noto Sans";
  padding: 8px 0px;
  background-color: transparent;
  color: white;
}

.sidebar-bottom-fixed-add-photo {
  display: flex;
  width: 100%;
}

.sidebar-bottom-fixed-add-photo button {
  border: 0.5px solid rgb(194, 194, 194);
  background-color: transparent;
  border-radius: 6px;
  cursor: pointer;
  font-size: 12px;
  font-family: "Noto Sans";
  padding: 12px 0px;
  color: white;
  margin-bottom: 150px;
  width: 100%;
}

.sidebar-bottom-fixed-cover.clicked {
  background-color: #7544b3;
}

.sidebar-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100000000;
}

.sidebar-popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
  gap: 10px;
  display: flex;
  flex-direction: column;
  font-family: 'Inter';
  align-items: center;
}

.sidebar-popup-buttons {
  display: flex;
  gap: 10px;
}

.sidebar-popup-buttons2 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.sidebar-popup-button {
  color: white;
  background: rgb(21, 47, 107);
  border-radius: 6px;
  border: none;
  font-size: 14px;
  padding: 10px 16px;
  cursor: pointer;
}

.sidebar-popup-title {
  font-size: 20px;
  text-align: center;
  font-weight: 500;
}

.sidebar-popup-text {
  font-size: 14px;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 300;
  width: 70%;
}

.sidebar-popup-text2 {
  font-size: 14px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 10px;
  font-weight: 400;
  width: 100%;
}

.sidebar-popup-input {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.sidebar-popup-input label {
  font-size: 14px;  
}

.sidebar-popup-input input, .sidebar-popup-input select {
  border: 0.5px solid rgb(218, 218, 218);
  border-radius: 6px;
  padding: 3px 10px;
  font-size: 14px;  
  width: 100%;
  height: 40px;
}

.date-selects {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px
}

@media (max-width: 900px) {
  .sidebar-popup-content {
    width: 90%;
  }
}