.analytics-container {
    font-family: 'Inter';
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
    justify-content: center;
}

.analytics-title {
    font-size: 20px;
    text-align: center;
    margin-bottom: 100px;
}

.analytics-input {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 300px;
}

.analytics-input label {
    font-size: 12px;  
}

.analytics-input input, .analytics-input select {
    border: 0.5px solid rgb(218, 218, 218);
    border-radius: 6px;
    padding: 3px 10px;
    font-size: 14px;  
    width: 100%;
    height: 40px;
}

.analytics-button {
    color: white;
    background: rgb(21, 47, 107);
    border-radius: 6px;
    border: none;
    font-size: 14px;
    padding: 10px 16px;
    cursor: pointer;
}

.not-sure-checker {
    display: flex;
    align-items: center;
    gap: 7px
}

.not-sure-checker input {
    width: 16px;
}